import React from "react"
import { Link } from "gatsby"

export default () => (
    <header className="mt-8 border-b-2 m-8 md:mb-16">
        <ul className="flex flex-row-reverse mb-2 pr-2">
            <li className="ml-4 md:ml-16 lg:ml-24"><Link to="/">daniel</Link></li>
            <li className="ml-4"><Link to="/images">images</Link></li>            
            <li className="ml-4"><Link to="/about">about</Link></li>
            <li className="ml-4" ><Link to="/">home</Link></li>
        </ul>
    </header>
)